<template>
	<screen
		class="steps-view"
		size="large"
		style="background: transparent; box-shadow: none; position: relative"
	>
		<card
			class="body"
			size="large"
			:style="{ background: '#1EADFF', padding: '50px', margin: 'auto' }"
			type="black"
		>
			<div slot="title">
				<h1>Provide a Donation Source</h1>
			</div>
			<div slot="subtitle" :style="{ color: 'white', fontWeight: '600' }">
				Authentication in Progress
			</div>
			<div slot="body">
				<p>
					We’ve deposited 2 micro deposits into the bank account you
					provided. When they’ve been posted (within 2-3 business
					days), you can verify them using the option below.
				</p>

				<div class="verifyDeposits-wrapper">
					<ui-button @click="verifyLink">Verify Deposits</ui-button>
					<ui-button
						class="clear"
						@click="handleDeleteLink"
						:loading="isRemovingDonationSource"
						>Reset</ui-button
					>
				</div>
			</div>
		</card>
	</screen>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Screen from '../../ui/Screen'
import Card from '../../ui/Card'
import UiButton from '../../ui/Button'

export default {
	name: 'verify-view',
	components: { Screen, UiButton, Card },
	data() {
		return {
			publicKey: process.env.VUE_APP_plaidPublicKey,
			env: process.env.VUE_APP_plaidEnv,
			webhook: process.env.VUE_APP_plaidWebhook,
			clientName: 'RoundUp App',
			countryCodes: ['US'],
			isRemovingDonationSource: false
		}
	},
	computed: {
		...mapGetters([])
	},
	methods: {
		...mapActions([
			'getPublicToken',
			'refreshLink',
			'deleteLink',
			'getPublicToken'
		]),
		handleDeleteLink() {
			this.isRemovingDonationSource = true

			this.deleteLink()
				.then(() => {
					this.isRemovingDonationSource = false

					this.$swal({
						title: 'Success',
						text: 'Your bank has been deleted',
						showCancelButton: false,
						allowOutsideClick: false,
						allowEscapeKey: false
					})
				})
				.catch(err => {
					this.isRemovingDonationSource = false

					console.log(err)

					this.$swal(
						'Error',
						'It looks like we are unable to delete your bank at this time. Please contact support.'
					)
				})
		},
		verifyLink() {
			this.getPublicToken()
				.then(data => {
					const handler = window.Plaid.create({
						clientName: this.clientName,
						env: this.env,
						key: this.publicKey,
						onSuccess: res => {
							this.refreshLink({ token: res }).then(() => {
								this.$swal({
									title: 'Success',
									text:
										"You're all setup! Thanks for helping us turn pocket change into global change!",
									showCancelButton: false,
									allowOutsideClick: false,
									allowEscapeKey: false
								})
							})
						},
						onError: err => {
							console.log(err)
						},
						product: 'auth',
						token: data.public_token,
						webhook: this.webhook
					})
					handler.open()
				})
				.catch(err => {
					console.log(err)
					this.$swal(
						'Error',
						'It looks like we are unable to relink your bank at this time. Please contact support.'
					)
				})
		}
	},
	mounted() {
		if (!window.Plaid) {
			let plaidScript = document.createElement('script')
			plaidScript.setAttribute(
				'src',
				'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
			)
			document.head.appendChild(plaidScript)
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.card {
	&.body {
		color: $white;
		width: 50%;
		max-width: 600px;
		.card-header {
			padding-left: 20px;
			padding-right: 20px;
			margin-bottom: 10px;
			border-bottom: 0;
			.card-title {
				h1 {
					font-family: 'Bitter', serif;
					font-weight: normal;
					color: $white;
					margin: 0 0 20px 0;
				}
			}
		}
		.card-body {
			padding: 0;
		}
		p {
			margin: 0 0 20px 0;
		}
	}
}
.verifyDeposits-wrapper {
	display: grid;
	gap: 10px;
	grid-auto-flow: column;
	grid-auto-column: auto;
	justify-content: start;
}
</style>
